import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import {
  getNavByLang,
  getServiceNavByLang,
  routeNames,
} from '../../utils/helpers/routes'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import SocialMedias from '../SocialMedias/SocialMedias'
import { show, hide, showFixed } from './animations'
import { AnimationStateContext } from '../../utils/hooks/AnimationState'
import ServiceHeader from './ServiceHeader'
import { iconCalendar } from '../../utils/icons'
import { setCalendlyAnalytics } from '../../utils/helpers/utils'

const Header = () => {
  const { language } = useContext(I18nextContext)
  const { t } = useTranslation(['common'])
  const [navOpen, setNavOpen] = useState(false)

  const { isReady: hasPageTransitionDone } = useContext(AnimationStateContext)
  const $tl = useRef({ showFixed: null, show: null, hide: null })
  const $isVisible = useRef(true)
  const $isVisibleSticky = useRef(false)
  const $scrollPos = useRef(0)
  const $headerRef = useRef(0)
  const $headerOffset = useRef(0)
  const $scrollOffset = useRef(0)
  const [location, setLocation] = useState('')

  const handleNavOpen = () => {
    const body = document.querySelector('body').classList
    setNavOpen(!navOpen)
    if (!navOpen) {
      body.add('no-scroll')
    } else {
      body.remove('no-scroll')
    }
  }

  const serviceRoutes = useMemo(() => {
    return location.includes('/service/')
  }, [location])

  const onScroll = useCallback(() => {
    if (!hasPageTransitionDone) {
      return
    }

    const previousScroll = $scrollPos.current
    const scrollY = window.scrollY
    $scrollPos.current = scrollY

    const tl = $tl.current

    if (scrollY <= $scrollOffset.current) {
      if ($isVisible.current) {
        return
      }
      $isVisible.current = true
      $isVisibleSticky.current = false
      tl.hide && tl.hide.pause()
      tl.showFixed && tl.showFixed.pause()
      tl.show && tl.show.restart()
      return
    }

    if (scrollY < previousScroll) {
      if ($isVisibleSticky.current) {
        return
      }
      $isVisible.current = false
      $isVisibleSticky.current = true
      tl.hide && tl.hide.pause()
      tl.show && tl.show.pause()
      tl.showFixed && tl.showFixed.restart()
      return
    }

    if (!$isVisible.current && !$isVisibleSticky.current) {
      return
    }

    $isVisible.current = false
    $isVisibleSticky.current = false
    tl.showFixed && tl.showFixed.pause()
    tl.show && tl.show.pause()
    tl.hide && tl.hide.restart()
  }, [hasPageTransitionDone])

  const setTimelines = useCallback(() => {
    if ($headerOffset.current === undefined) {
      return
    }

    $tl.current.showFixed = showFixed($headerOffset.current)
    $tl.current.hide = hide(serviceRoutes ? '72px' : '100%')
    $tl.current.show = show()
  }, [serviceRoutes])

  const onResize = useCallback(() => {
    if (!$headerRef.current) {
      return
    }

    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--header-vh', `${vh}px`)

    const style = window.getComputedStyle($headerRef.current)
    const paddingTop = parseInt(style.getPropertyValue('padding-top'), 10)
    const paddingBottom = parseInt(style.getPropertyValue('padding-bottom'), 10)

    $headerOffset.current = paddingTop - paddingBottom
    $scrollOffset.current = $headerOffset.current

    setTimelines()
  }, [setTimelines])

  useEffect(() => {
    setLocation(window.location.pathname)
    const body = document.querySelector('body').classList
    return () => {
      body.remove('no-scroll')
    }
  }, [])

  const nav = useMemo(() => getNavByLang(language), [language])
  const servicesNav = useMemo(() => getServiceNavByLang(language), [language])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('scroll', onResize)
  }, [onResize])

  useEffect(() => {
    if (!$tl.current || !$tl.current.show || !hasPageTransitionDone) {
      return
    }
    $tl.current.show.play()
  }, [hasPageTransitionDone])

  useEffect(() => {
    const tl = $tl.current

    return () => {
      tl.show && tl.show.kill()
      tl.showFixed && tl.showFixed.kill()
      tl.hide && tl.hide.kill()
    }
  }, [setTimelines])

  const currentService = useMemo(() => {
    const serviceId = servicesNav.find(
      (service) => location === `${service.slug}/`
    )?.id
    return t(`common:nav:${serviceId}`) || ''
  }, [location, servicesNav, t])

  return (
    <>
      <header className="header header--light" ref={$headerRef}>
        <div className={`header__main ${navOpen ? 'is-open' : ''}`}>
          <div className="container container-wide">
            <div className="header__top">
              <LocalizedTransitionLink to="/">
                <img
                  width="109"
                  height="14"
                  className="header__logo header__logo--white"
                  src="/images/logo-white.svg"
                  alt="Logo source white"
                  title="Source"
                />
                <img
                  className="header__logo header__logo--black"
                  src="/images/logo-black.svg"
                  alt="Logo source black"
                  title="Source"
                />
              </LocalizedTransitionLink>
              <div
                role="button"
                tabIndex={0}
                className="header__mobile"
                onClick={handleNavOpen}
                onKeyPress={handleNavOpen}
              >
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="header__body">
              <nav className="nav">
                <ul className="hide-desktop">
                  {servicesNav.map((page) => {
                    return (
                      <li className="nav__item" key={page.id}>
                        <LocalizedTransitionLink to={page.slug} partiallyActive>
                          {t(`common:nav:${page.id}`)}
                        </LocalizedTransitionLink>
                      </li>
                    )
                  })}
                </ul>
                <ul>
                  {nav.map((page, idx) => {
                    return (
                      <li className="nav__item" key={page.id}>
                        <LocalizedTransitionLink
                          to={page.slug}
                          partiallyActive
                          className={` ${
                            page.id === routeNames.services
                              ? ' hide-mobile'
                              : ''
                          } ${
                            page.id === routeNames.services &&
                            location.includes('/service/')
                              ? 'is-active'
                              : ''
                          }`}
                        >
                          {t(`common:nav:${page.id}`)}
                        </LocalizedTransitionLink>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
            <div className="header__bottom">
              <SocialMedias />
            </div>
          </div>
        </div>
        {serviceRoutes && <ServiceHeader />}
      </header>
      {serviceRoutes && (
        <div className="container container--calendly">
          <a
            href="https://calendly.com/meet-source/ "
            className="btn btn--small btn--calendly"
            target="_blank"
            onClick={() => setCalendlyAnalytics(currentService)}
            rel="noreferrer"
          >
            <>{iconCalendar}</>
            {t('service:calendly')}
          </a>
        </div>
      )}
    </>
  )
}

export default Header
